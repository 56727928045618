import { Injectable } from '@angular/core';
import { ToolBundle } from '../../../interfaces/tool-interfaces/tool.interface';
import { concatMap, map, Observable, take } from 'rxjs';
import { selectTool } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ToolService extends ContributorsDashboardEntityService<ToolBundle> {
  tool$: Observable<ToolBundle | null> = new Observable<ToolBundle | null>();

  constructor(store: Store, contributorsDashboardHelperService: ContributorsDashboardHelperService, http: HttpClient) {
    super(store, contributorsDashboardHelperService, http, 'tools');
    this.tool$ = this.store.select(selectTool);
  }

  // getTools(contributorId?: string, from?: number, query?: string): Observable<ContributorsApiResponse<ToolBundle>> {
  //   return this.getTools(from, query, contributorId);
  // }

  toggleToolActive(tool: ToolBundle): Observable<ToolBundle> {
    tool = { ...tool, active: !tool.active };

    return this.tool$.pipe(
      take(1),
      concatMap(() => this.updateTool(tool)),
      concatMap((res) => {
        if (res) {
          const state = res?.active ? 'Activate' : 'Deactivate';
          const { name } = res.tool;

          return this.contributorsDashboardHelperService
            .onOpenGenericModal(`Successfully ${state} "${name}" tool record!`)
            .pipe(map(() => res));
        } else {
          throw new Error('Failed to update tool state.');
        }
      })
    );
  }
}
