import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, of, withLatestFrom } from 'rxjs';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { ContributorsDashboardActions } from './contributors-dashboard.actions';
import { ContributorsApiResponse } from '../../../features/dashboard/contributors-dashboard/interfaces/contrbutorsApiResponse.interface';
import { Store } from '@ngrx/store';
import * as ContributorsDashboardSelectors from '../../state/contributors-dashboard/contributors-dashboard.selectors';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { Vocabulary } from '../../../features/dashboard/shared/interfaces/vocabulary.interface';
import { DatasourceFull } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { ToolBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/tool-interfaces/tool.interface';
import { ContributorService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/contributor.service';
import { ServiceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/service.service';
import { DatasourceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/datasource.service';
import { TrainingResourceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/training-resource.service';
import { InteroperabilityService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/interoperability.service';
import { ToolService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/tool.service';

@Injectable()
export class ContributorsDashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private contributorService: ContributorService,
    private serviceService: ServiceService,
    private datasourceService: DatasourceService,
    private trainingResourceService: TrainingResourceService,
    private interoperabilityService: InteroperabilityService,
    private toolService: ToolService
  ) {}

  loadContributors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadContributors),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorService.getProviders(action.from, action.query).pipe(
          map((data: ContributorsApiResponse<ProviderBundle>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadContributorsSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServices),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.serviceService.getServices(action.contributor, action.from, action.query).pipe(
          map((data: ContributorsApiResponse<ServiceBundle>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadServicesSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadDatasources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadDatasources),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.datasourceService.getDatasources(action.contributor, action.from, action.query).pipe(
          map((data: ContributorsApiResponse<DatasourceFull>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadDatasourcesSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadTrainingResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTrainingResources),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.trainingResourceService.getTrainingResources(action.contributor, action.from, action.query).pipe(
          map((data: ContributorsApiResponse<TrainingResourceBundle>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadTrainingResourcesSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadInteroperabilityRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadInteroperabilityRecords),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.interoperabilityService
          .getInteroperabilityRecords(action.contributor, action.from, action.query)
          .pipe(
            map((data: ContributorsApiResponse<InteroperabilityRecordBundle>) => {
              this.store.dispatch(ContributorsDashboardActions.decrementLoading());
              return ContributorsDashboardActions.loadInteroperabilityRecordsSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              });
            })
          );
      })
    )
  );

  loadTools$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTools),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.toolService.getTools(action.from, action.query, action.contributor).pipe(
          map((data: ContributorsApiResponse<ToolBundle>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadToolsSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadVocabularies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadVocabularies),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectVocabularies)),
      mergeMap(([action, cachedData]) => {
        if (cachedData && cachedData.hasOwnProperty(action.vocabularyType)) {
          return of(ContributorsDashboardActions.loadVocabulariesCached());
        }
        return this.contributorService.getVocabularyByType(action.vocabularyType).pipe(
          map((vocabulary: Vocabulary[]) =>
            ContributorsDashboardActions.loadVocabulariesSuccess({
              vocabularyType: action.vocabularyType,
              vocabulary,
            })
          )
        );
      })
    )
  );

  loadServiceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServiceType),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectServiceType)),
      mergeMap(([action, cachedData]) => {
        if (cachedData) {
          return of(ContributorsDashboardActions.loadServiceTypeCached());
        }
        return this.contributorService
          .getOfferParameters()
          .pipe(map((serviceType) => ContributorsDashboardActions.loadServiceTypeSuccess({ serviceType })));
      })
    )
  );

  loadServiceOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServiceOffers),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectServiceOffers)),
      mergeMap(([action, cachedData]) => {
        if (cachedData?.length) {
          return of(ContributorsDashboardActions.loadServiceOffersCached());
        }
        return this.contributorService
          .getUserOffers(action.serviceId)
          .pipe(map((serviceOffers) => ContributorsDashboardActions.loadServiceOffersSuccess({ serviceOffers })));
      })
    )
  );
}
