import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { ServiceType } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/serviceType.interface';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { Offer } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/offer.interface';
import { Vocabulary } from '../../../features/dashboard/shared/interfaces/vocabulary.interface';
import { DatasourceFull } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { ToolBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/tool-interfaces/tool.interface';

export interface ContributorsDashboardFormState {
  contributorMode: boolean;
  contributor: ProviderBundle | null;
  contributors: { total: number; results: ProviderBundle[]; from: number };
  service: ServiceBundle | null;
  services: { total: number; results: ServiceBundle[]; from: number };
  datasource: DatasourceFull | null;
  datasources: { total: number; results: DatasourceFull[]; from: number };
  trainingResource: TrainingResourceBundle | null;
  trainingResources: { total: number; results: TrainingResourceBundle[]; from: number };
  interoperabilityRecord: InteroperabilityRecordBundle | null;
  interoperabilityRecords: { total: number; results: InteroperabilityRecordBundle[]; from: number };
  tool: ToolBundle | null;
  tools: { total: number; results: ToolBundle[]; from: number };
  vocabularies: { [key: string]: Vocabulary[] };
  serviceType: ServiceType | null;
  serviceOffers: Offer[];
  serviceOffer: Offer | null;
  loadingCount: number;
  error: string;
}

export const initialState: ContributorsDashboardFormState = {
  contributorMode: false,
  contributor: null,
  contributors: { total: 0, results: [], from: 0 },
  service: null,
  services: { total: 0, results: [], from: 0 },
  datasource: null,
  datasources: { total: 0, results: [], from: 0 },
  trainingResource: null,
  trainingResources: { total: 0, results: [], from: 0 },
  interoperabilityRecord: null,
  interoperabilityRecords: { total: 0, results: [], from: 0 },
  tool: null,
  tools: {
    total: 0,
    results: [],
    from: 0,
  },
  vocabularies: {},
  serviceType: null,
  serviceOffers: [],
  serviceOffer: null,
  loadingCount: 0,
  error: '',
};
