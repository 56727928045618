import { Injectable } from '@angular/core';
import { ContributorForm } from '../../../interfaces/form-interfaces/contributorForm.interface';
import { ProviderBundle } from '../../../interfaces/provider-interfaces/provider.interface';
import { concatMap, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectContributor } from '../../../../../../core/state/contributors-dashboard/contributors-dashboard.selectors';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { HttpClient } from '@angular/common/http';
import { ContirbutorHelperService } from './helpers/contirbutor-helper.service';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { BpmTask } from '../../../interfaces/bpmTaskApiResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class ContributorService extends ContributorsDashboardEntityService<ProviderBundle> {
  contributor$: Observable<ProviderBundle | null> = new Observable<ProviderBundle | null>();

  constructor(
    store: Store,
    contributorsDashboardHelperService: ContributorsDashboardHelperService,
    http: HttpClient,
    private contributorHelperService: ContirbutorHelperService
  ) {
    super(store, contributorsDashboardHelperService, http, 'providers');
    this.contributor$ = this.store.select(selectContributor);
  }

  getProviders(from?: number, query?: string): Observable<ContributorsApiResponse<ProviderBundle>> {
    return this.getEntities(from, query);
  }

  getProviderWorkflow(providerId: string): Observable<BpmTask[]> {
    return this.getEntityWorkflow(providerId);
  }

  saveDraftContributor(contributorModel: ContributorForm): Observable<string> {
    return this.saveEntity(this.saveContributorRequest(contributorModel)).pipe(
      concatMap((res) =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully created ${res.provider?.name} contributor!`
        )
      )
    );
  }

  reviewContributor(
    action: 'approve' | 'reject',
    contributorWorkflowId: string,
    comments: any = null
  ): Observable<string> {
    return this.contributor$.pipe(
      concatMap((contributor) => this.approveRejectEntity(action, contributor!, contributorWorkflowId, comments)),
      concatMap(() =>
        this.contributorsDashboardHelperService.onOpenGenericModal(
          `Successfully ${action == 'approve' ? 'approved' : 'rejected'} contributor!`
        )
      )
    );
  }

  saveContributorRequest(contributorModel: ContributorForm): ProviderBundle {
    return {
      provider: this.contributorHelperService.contributorFormModelToContributorInterface(contributorModel),
    } as ProviderBundle;
  }
}
