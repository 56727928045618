<h2>{{ props['label'] }}</h2>
@for (field of field.fieldGroup; track $index) {
<div class="mt-3 grid grid-cols-12">
  <div class="col-span-11" [class]="!formControl.disable ? 'col-span-11' : 'col-span-12'">
    <formly-field [field]="field"></formly-field>
  </div>
  @if (!$first && !formControl.disabled) {
  <div class="flex flex-col justify-center">
    <button mat-icon-button color="primary" (click)="remove($index)"><mat-icon fontIcon="close"></mat-icon></button>
  </div>
  }
</div>
} @if (!formControl.disabled) {
<div class="mt-2 mb-4 flex justify-center">
  <button mat-flat-button color="primary" (click)="add()">
    <mat-icon [fontIcon]="props['addIcon']"></mat-icon>
    {{ props['addText'] }}
  </button>
</div>
}
